import { Expose, Transform } from 'class-transformer';
import { isAfter, isBefore, isSameDay, parseISO } from 'date-fns';
import { Administrator } from './administrator';
import { Category } from './category';
import { Company } from './company';
import { Entry } from './entry';
import { Influencer } from './influencer';
import { Medium } from './medium';
import { Offer, OfferPivot } from './offer';
import { PostingReport } from './posting-report';
import AbstractDescriptionFeature = Campaign.AbstractDescriptionFeature;
import DescriptionFeatureType = Campaign.DescriptionFeatureType;

const convertToInterval = (
  value: { start: string; end: string } | null
): Interval | null => {
  if (value === null) {
    return null;
  }

  return {
    start: parseISO(value.start),
    end: parseISO(value.end),
  };
};

export class Campaign {
  @Expose({ name: 'posting_deadlines', toClassOnly: true })
  @Transform((value) => convertToInterval(value), { toClassOnly: true })
  postingDeadlines?: Interval;

  id!: number;
  company_id!: number;
  category_id!: number;
  name!: string;
  website?: string;
  public!: boolean;
  description!: string | null;
  features!: AbstractDescriptionFeature[];
  company_introduction!: string;
  company_philosophy!: string;
  header_image?: Blob;
  recruit_start_date!: string;
  recruit_end_date!: string;
  selection_start_date!: string;
  selection_end_date!: string;

  // 関連エンティティ
  company!: Company;
  category!: Category;
  event?: Campaign.Event;
  sampling?: Campaign.Sampling;
  requirement?: Campaign.Requirement;
  reviews: Campaign.ReviewRequest[] = [];
  offers: Offer[] = [];
  entries: Entry[] = [];
  posting_reports: PostingReport[] = [];
  influencer_offers: OfferPivot[] = [];

  // API生成プロパティ
  header_image_url!: string;
  features_and_philosophies!: AbstractDescriptionFeature[];

  isOutOfRecruitPeriod(now: Date = new Date()): boolean {
    const recruitStartDate = parseISO(this.recruit_start_date);
    if (!isSameDay(now, recruitStartDate) && isBefore(now, recruitStartDate)) {
      return true;
    }

    const recruitEndDate = parseISO(this.recruit_end_date);
    return !isSameDay(now, recruitEndDate) && isAfter(now, recruitEndDate);
  }

  isReviewCreatable(): boolean {
    const lastReview = this.lastReview();
    if (lastReview === null) {
      return true;
    }

    return lastReview.approval === false;
  }

  lastReview(): Campaign.ReviewRequest | null {
    if (!this.reviews || this.reviews.length === 0) {
      return null;
    }
    return this.reviews[this.reviews.length - 1];
  }

  getFeatures(type: DescriptionFeatureType): AbstractDescriptionFeature[] {
    if (!this.features_and_philosophies) {
      return [];
    }

    return this.features_and_philosophies.filter((item) => item.type === type);
  }

  detailData(): { [key: string]: any } {
    let type = Campaign.Type.SAMPLING;
    switch (true) {
      case !!(this.sampling && !this.event):
        type = Campaign.Type.SAMPLING;
        break;
      case !!(!this.sampling && this.event):
        type = Campaign.Type.EVENT;
        break;
    }

    return {
      type,
      event: this.event,
      sampling: this.sampling,
    };
  }

  descriptionData(): { [key: string]: any } {
    return {
      features: this.features_and_philosophies.filter(
        (item) => item.type === 'feature'
      ),
      philosophies: this.features_and_philosophies.filter(
        (item) => item.type === 'philosophy'
      ),
      company_introduction: this.company_introduction,
      company_philosophy: this.company_philosophy,
    };
  }

  requirementData(): { [key: string]: any } {
    const returnValue = this.requirement as { [key: string]: any };

    switch (true) {
      case !!(
        this.requirement?.grace_days &&
        !this.requirement?.beginning &&
        !this.requirement?.deadline
      ):
        returnValue.postType = Campaign.PostType.TO_DAY.code;
        break;
      case !!(
        !this.requirement?.grace_days &&
        this.requirement?.beginning &&
        this.requirement?.deadline
      ):
        returnValue.postType = Campaign.PostType.TO_TERM.code;
        break;
    }

    return returnValue;
  }

  rewardData(): { [key: string]: any } {
    const returnValue = this.requirement?.reward as { [key: string]: any };

    if (!returnValue) {
      return {};
    }

    delete returnValue.campaign_requirement_id;

    return returnValue;
  }

  hasOffer(influencer?: Influencer): boolean | null {
    if (!influencer) {
      return null;
    }

    const result = !!this.offers.find(
      (offer) => !!offer.influencers.find((tmp) => tmp.id === influencer?.id)
    );

    if (result) {
      return true;
    }

    return !!this.influencer_offers.find(
      (influencerOffer) => influencerOffer.influencer_id === influencer?.id
    );
  }

  forInstagram(): boolean {
    return this.requirement?.medium_id === Medium.INSTAGRAM.id;
  }
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Campaign {
  export interface DescriptionInterface {
    company_introduction: string;
    company_philosophy: string;
    features: { [key: string]: AbstractDescriptionFeature };
    philosophies: { [key: string]: AbstractDescriptionFeature };
  }

  export interface AbstractDescriptionFeature {
    title: string;
    body: string;
    image?: Blob;
    type: DescriptionFeatureType;

    // API生成プロパティ
    image_url?: string;
  }

  export type DescriptionFeatureType = 'feature' | 'philosophy';

  export class Description implements DescriptionInterface {
    company_introduction!: string;
    company_philosophy!: string;
    features!: { [key: string]: Campaign.AbstractDescriptionFeature };
    philosophies!: { [key: string]: Campaign.AbstractDescriptionFeature };

    constructor(props: { [key: string]: any }) {
      Object.assign(this as { [key: string]: any }, props);
    }

    flattenPropsForFormData(): { [key: string]: any } {
      const result: { [key: string]: any } = {
        company_introduction: this.company_introduction,
        company_philosophy: this.company_philosophy,
      };

      let i = 0;
      for (const prop of ['features', 'philosophies']) {
        const formData = this[prop as keyof Description] as {
          [key: string]: any;
        };
        if (formData) {
          for (const formName in formData) {
            if (formData.hasOwnProperty(formName)) {
              const feature = formData[formName] as AbstractDescriptionFeature;
              for (const featureProp in feature) {
                if (feature.hasOwnProperty(featureProp)) {
                  result[`features[${i}][${featureProp}]`] =
                    feature[featureProp as keyof AbstractDescriptionFeature];
                }
              }
              result[`features[${i}][type]`] =
                prop === 'features' ? 'feature' : 'philosophy';
              i++;
            }
          }
        }
      }

      return result;
    }
  }

  export interface Event {
    start_date: string;
    end_date: string;
    start_time?: string;
    end_time?: string;
    postcode: string;
    prefecture: number;
    address: string;
    with_companion: boolean;
    include_transportation: boolean;
  }

  export interface Sampling {
    delivery_days: number;
  }

  export interface Reward {
    type: number;
    amount: number;
  }

  export interface Requirement {
    medium_id: number;
    beginning?: string;
    deadline?: string;
    grace_days?: number;
    mandatory?: string;
    show_ones_face: boolean;
    tagging_into_post: boolean;
    include_account_name_in_post_caption: boolean;
    other_notes_for_posting_procedure?: string;
    flow_after_hiring: string;
    note: string;
    secondary_use: boolean;

    // 関連エンティティ
    reward?: Campaign.Reward;
    instagram?: {
      min_follower?: number;
      hashtag?: string[];
      tags_in_image?: string[];
      tags_in_caption?: string[];
      required_partnership_tags: boolean;
      partnership_tags?: string[];
    };
  }

  export class PostType {
    static readonly TO_TERM = new PostType(0, '期間指定');
    static readonly TO_DAY = new PostType(1, '日数指定');

    private constructor(public code: number, public name: string) {}

    static all(): Array<PostType> {
      return [PostType.TO_TERM, PostType.TO_DAY];
    }
  }

  export class Type {
    static readonly EVENT = new Type(0, 'イベント参加');
    static readonly SAMPLING = new Type(1, '商品提供');

    private constructor(public code: number, public name: string) {}

    public static all(): Type[] {
      return [Type.EVENT, Type.SAMPLING];
    }
  }

  export interface ReviewRequest {
    id: number;
    campaign_id: number;
    administrator_id?: number;
    approval?: boolean | null;
    cause_for_rejection?: string;

    // 関連プロパティ
    campaign: Campaign;
    administrator?: Administrator;

    created_at?: string;
    updated_at?: string;
  }
}
