import { HttpBackend, HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MAINTENANCE_OPTION } from '../injection-tokens';
import { MaintenanceCondition } from '../models/maintenance-condition';
import { MaintenanceOption } from '../models/maintenance-option';

@Injectable({
  providedIn: 'root',
})
export class AdministrationService {
  private readonly uninterceptHttpClient: HttpClient;

  constructor(
    backend: HttpBackend,
    @Inject(MAINTENANCE_OPTION)
    private readonly maintenanceOption: MaintenanceOption
  ) {
    this.uninterceptHttpClient = new HttpClient(backend);
  }

  enableMaintenanceMode(): boolean {
    return this.maintenanceOption.enabled;
  }

  getMaintenanceStatus(): Observable<MaintenanceCondition | undefined> {
    return this.uninterceptHttpClient
      .get<{ contents: MaintenanceCondition[] }>(
        this.maintenanceOption.endpoint,
        {
          params: {
            limit: '1',
            filters: `name[equals]${this.maintenanceOption.targetName}`,
            fields: 'in_maintenance,schedule_start_date,schedule_end_date',
          },
          headers: {
            // eslint-disable-next-line
            'X-MICROCMS-API-KEY': this.maintenanceOption.apiKey,
          },
          withCredentials: false,
        }
      )
      .pipe(map(({ contents }) => contents[0]));
  }
}
