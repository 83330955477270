import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';

let IGNORE_HTTP_EVENT = false;
const ignoredHttpEvents: string[] = [];

@Injectable()
export abstract class LoadIndicatorInterceptor implements HttpInterceptor {

  protected count = 0;
  protected ref: MatDialogRef<any> | null = null;

  static disable(): void
  {
    IGNORE_HTTP_EVENT = true;
  }

  static enable(): void
  {
    IGNORE_HTTP_EVENT = false;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(event => {
        if (event.type !== HttpEventType.Sent) {
          return;
        }
        if (IGNORE_HTTP_EVENT) {
          return this.markAsIgnored(request.url);
        }
        this.addConnection();
      }),
      tap(event => {
        if (event.type !== HttpEventType.Response) {
          return;
        }
        if (this.isIgnored(request.url)) {
          return this.popFromIgnored(request.url);
        }
        this.releaseConnection();
      }),
      catchError(err => {
        if (!(err instanceof HttpErrorResponse)) {
          return throwError(err);
        }
        if (this.isIgnored(request.url)) {
          this.popFromIgnored(request.url);
          return throwError(err);
        }
        this.releaseConnection();
        return throwError(err);
      })
    );
  }

  private addConnection(): void {
    if (this.count === 0) {
      this.ref = this.getDialog();
    }

    this.count += 1;
  }

  private releaseConnection(): void {
    this.count -= 1;
    if (this.count !== 0) {
      return;
    }

    this.ref?.close();
    this.ref = null;
  }

  private markAsIgnored(url: string): void
  {
    ignoredHttpEvents.push(url);
  }

  private popFromIgnored(url: string): void
  {
    const idx = ignoredHttpEvents.indexOf(url);
    if (idx > -1) {
      ignoredHttpEvents.splice(idx, 1);
    }
  }

  private isIgnored(url: string): boolean
  {
    return ignoredHttpEvents.includes(url);
  }

  protected abstract getDialog(): MatDialogRef<any>
}
